<template>
  <div>
    <ContractsShow
      :is-show="true"
      :orderid="orderId"
    />
  </div>
</template>

<script>
import ContractsShow from '../show.vue'

export default {
  components: { ContractsShow },
  computed: {
    orderId() {
      return this.$route.params.id
    },
  },
}
</script>

<style>

</style>
